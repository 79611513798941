import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// A theme with custom primary and secondary color.
const TOP_BAR_HEIGHT = 64;
const NAVBAR_HEIGHT = 56;
const FILTER_FORM_WIDTH = 220;
const TOOLBAR_HEIGHT = 48;
const SIDEBAR_WIDTH = 260;

const baseTheme = createTheme();

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          overflowY: 'hidden',
        },

        body: {
          height: '100%',
          overflowY: 'hidden',
          '& > #root': {
            height: '100%',
          },
          "-webkitOverflowScrolling": 'touch',
        },
      },
    },
  },

  //----------------------------------------------------------------------------
  // Material-UI Theme

  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#16aa5f',
    },
    secondary: {
      main: red[500],
    },
  },

  mixins: {
    layout: {
      split: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 16,
      },

      splitStack: {
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr',
        gridGap: 16,

        [baseTheme.breakpoints.up('md')]: {
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr 1fr',
        },
      },
    },

    simpleDialog: {
      dialogTitle: {
        alignItems: 'center',
        display: 'flex',
        gap: baseTheme.spacing(1),
        padding: baseTheme.spacing(1, 2),
        position: 'relative',
      },

      headingContainer: {
        flex: 1,
      },

      dialogSubtitle: {
        color: baseTheme.palette.text.secondary,
        fontSize: baseTheme.typography.pxToRem(14),
      },

      dialogContent: {
        padding: baseTheme.spacing(2),
      },

      dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        gap: baseTheme.spacing(1),
        padding: baseTheme.spacing(1),

        [baseTheme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
      },

      loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 2,
      },

      commitButton: {
        [baseTheme.breakpoints.up('md')]: {
          order: 1,
        },
      },
    },

    detailView: {
      main: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        [baseTheme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      },

      content: {
        padding: baseTheme.spacing(2),

        [baseTheme.breakpoints.up('md')]: {
          flex: 1,
        },
      },

      aside: {
        padding: baseTheme.spacing(2),

        [baseTheme.breakpoints.up('md')]: {
          borderLeft: `1px solid ${baseTheme.palette.grey[200]}`,
          order: 1,
          width: 320,
        },
      },
    },

    status: {
      successBgColor: {
        backgroundColor: baseTheme.palette.success.main,
      },
      infoBgColor: {
        backgroundColor: baseTheme.palette.info.main,
      },
      warningBgColor: {
        backgroundColor: baseTheme.palette.warning.main,
      },
      errorBgColor: {
        backgroundColor: baseTheme.palette.error.main,
      },
      cancelledBgColor: {
        backgroundColor: baseTheme.palette.common.black,
      },
      successColor: {
        color: baseTheme.palette.success.main,
      },
      infoColor: {
        color: baseTheme.palette.info.main,
      },
      warningColor: {
        color: baseTheme.palette.warning.main,
      },
      errorColor: {
        color: baseTheme.palette.error.main,
      },
      cancelledColor: {
        color: baseTheme.palette.common.black,
      },
    },

    toolbar: {
      [baseTheme.breakpoints.down('md')]: {
        minHeight: NAVBAR_HEIGHT,
      },
    },

    pageTitle: {
      fontSize: baseTheme.typography.pxToRem(24),
    },

    metadataGrid: {
      borderBottom: `1px solid ${baseTheme.palette.grey[400]}`,
      borderTop: `1px solid ${baseTheme.palette.grey[400]}`,
      paddingBottom: baseTheme.spacing(2),
      paddingTop: baseTheme.spacing(2),
      marginBottom: baseTheme.spacing(3),
      marginTop: baseTheme.spacing(2),

      '& > div:first-child': {
        [baseTheme.breakpoints.down('sm')]: {
          paddingBottom: baseTheme.spacing(2),
        },

        [baseTheme.breakpoints.up('md')]: {
          paddingRight: baseTheme.spacing(1),
        },
      },

      '& > div:not(:first-child)': {
        [baseTheme.breakpoints.down('sm')]: {
          borderTop: `1px solid ${baseTheme.palette.grey[400]}`,
          paddingTop: baseTheme.spacing(2),
        },

        [baseTheme.breakpoints.up('md')]: {
          borderLeft: `1px solid ${baseTheme.palette.grey[400]}`,
          paddingLeft: baseTheme.spacing(2),
        },
      },
    },

    buttons: {
      linkButton: {
        fontSize: baseTheme.typography.pxToRem(14),
        fontWeight: 400,
        minWidth: 'unset',
        padding: `1px 2px`,
        textAlign: 'left',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }
  },

  typography: {
    button: {
      textTransform: 'none',
    },

    h1: {
      fontSize: baseTheme.typography.pxToRem(24),
    },

    h2: {
      fontSize: baseTheme.typography.pxToRem(20),
      fontWeight: 500,
    },

    h3: {
      fontSize: baseTheme.typography.pxToRem(18),
      fontWeight: 500,
    },

    h4: {
      fontSize: baseTheme.typography.pxToRem(16),
      fontWeight: 500,
    },

    h5: {
      fontSize: baseTheme.typography.pxToRem(14),
      fontWeight: 500,
    },

    h6: {
      fontSize: baseTheme.typography.pxToRem(12),
    },
  },

  //----------------------------------------------------------------------------
  // Application Theme
  //----------------------------------------------------------------------------
  appbar: {
    height: 56,
  },

  navbar: {
    height: NAVBAR_HEIGHT,
  },

  toolbar: {
    height: 48,
  },

  filterForm: {
    width: FILTER_FORM_WIDTH,
  },

  topbar: {
    height: TOP_BAR_HEIGHT,
  },


  sidebar: {
    width: SIDEBAR_WIDTH,
  },

  navigationController: {
    navbarHeight: NAVBAR_HEIGHT,
    toolbarHeight: TOOLBAR_HEIGHT,
  },

  scrollView: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
  },

  sizes: {
    navigationController: {
      navbarHeight: NAVBAR_HEIGHT,
      toolbarHeight: TOOLBAR_HEIGHT,
    },
  },

  // FORM
  form: {
    inputLabel: {
      textTransform: 'capitalize',
    },

    customControl: {
      fieldset: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: baseTheme.shape.borderRadius,
        borderWidth: 1,
        minHeight: baseTheme.spacing(8),
      },

      legend: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        textTransform: 'capitalize',
      },
    },
  },


  // LIST DIALOG
  listDialog: {
    filterField: {
      backgroundColor: '#FFF',
      borderRadius: 4,
      padding: '0 16px',
      marginBottom: 16,
    },

    paper: {
      minWidth: 320,
      width: 480,
    },

    progressBar: {
      height: 2,
    },
  },

  // EDIT DIALOG
  editDialog: {
    paper: {
      minWidth: 320,
      width: 480,
      overflow: 'visible',
    },

    dialogContent: {
      overflow: 'visible',
    },
  },

  // LIST VIEW
  listView: {
    list: {
      padding: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    subheader: {
      backgroundColor: '#e7e7e7',
    },
  },


  // DATA CARD
  dataCard: {
    modeToggleButton: {
      padding: baseTheme.spacing(0.5),
    },

    cardHeaderAction: {
      marginTop: 0,
    },

    cardHeaderTitle: {
      fontSize: baseTheme.typography.pxToRem(18),
    },

    cardHeaderRoot: {
      backgroundColor: baseTheme.palette.grey[50],
      borderBottom: `1px solid ${baseTheme.palette.grey[200]}`,
      padding: baseTheme.spacing(1, 2),
    },

    cardActionsRoot: {
      borderTop: `1px solid ${baseTheme.palette.grey[200]}`,
    },

    cardContentRoot: {
      padding: '0 !important',
    },

    plainCardHeaderRoot: {
      padding: baseTheme.spacing(0, 2, 0, 0),
    },
  },

  // PROPERTY LIST
  propertyList: {
    listItem: {
      listItemRoot: {
        alignItems: 'start',
        display: 'inline-grid',
        gridColumnGap: 4,
        gridTemplateColumns: 'min-content 1fr',
        padding: '1px 0',
      },

      listItemIconRoot: {
        minWidth: 20,
        paddingTop: 1,
      },

      listItemIcon: {
        height: 18,
        width: 18,
      },

      listItemTextRoot: {
        margin: 0,
        padding: 0,
      },

      label: {
        fontWeight: 500,
        whiteSpace: 'nowrap',
        "&:after": {
          content: '":"',
        },
      },

      inlineNestedList: {
        display: 'inline-flex',

        '& > *:not(:last-child)': {
          marginRight: baseTheme.spacing(0.5),
          '&:after': {
            content: '","',
          },
        },
      },

      nestedListItem: {
        padding: 0,
        width: 'initial',
      },
    },
  },

  // ICONS
  icons: {
    secondaryListItemIcon: {
      backgroundColor: '#aaa',
      borderRadius: 5,
      color: '#fff',
      fontSize: baseTheme.typography.pxToRem(18),
      margin: baseTheme.spacing(0, 0.5),
      padding: 3,
    },
  },
});

export default createTheme(theme);
