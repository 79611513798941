import { jwtDecode } from "jwt-decode";

import ServiceAgent from '@material-appkit/core/util/ServiceAgent';

import ServiceProxy from '../io/ServiceProxy';

import apiUrls from 'api-urls';

import {
  E_ACCESS_TOKEN_REFRESH,
  E_REFRESH_TOKEN_UNAVAILABLE,
} from '../constants/errors';

class AuthManager {
  static get authInfo() {
    const accessToken = ServiceProxy.getAccessToken();
    if (!accessToken) {
      return null;
    }
    return jwtDecode(accessToken);
  }

  static get currentUserId() {
    return this.authInfo ? this.authInfo.user_id : null;
  }

  static getAccessToken() {
    return ServiceProxy.getAccessToken();
  }
  static setAccessToken(value) {
    ServiceProxy.setAccessToken(value);
  }


  static getRefreshToken() {
    return ServiceProxy.getRefreshToken();
  }
  static setRefreshToken(value) {
    ServiceProxy.setRefreshToken(value);
  }


  static processAuthResponse(tokens) {
    this.setAccessToken(tokens.access);
    this.setRefreshToken(tokens.refresh);
  }


  static async authenticate(credentials) {
    try {
      const res = await ServiceAgent.post({
        endpoint: apiUrls.auth.jwt.create,
        params: credentials,
        authenticate: false,
      });
      this.processAuthResponse(res.jsonData);
      return res;
    } catch (err) {
      this.logout();
      throw err;
    }
  }


  static async verifyOTP(params) {
    try {
      const res = await ServiceAgent.post({
        endpoint: apiUrls.auth.otp.verify,
        params,
        authenticate: false,
      });
      this.processAuthResponse(res.jsonData);
      return res;
    } catch (err) {
      this.logout();
      throw err;
    }
  }


  static refreshAccessToken() {
    return new Promise((resolve, reject) => {
      const refreshToken = this.getRefreshToken();
      if (refreshToken) {
        const params = { refresh: refreshToken };
        const requestHeaders = ServiceProxy.getRequestHeaders(null, params, false);
        const requestInfo = ServiceProxy.buildRequestInfo('POST',  apiUrls.auth.jwt.refresh, params, requestHeaders);

        fetch(requestInfo.url, requestInfo.options)
          .then((res) => {
            if (res.status === 200) {
              res.json().then((jsonData) => {
                res.jsonData = jsonData;
                this.setAccessToken(jsonData.access);
                this.setRefreshToken(jsonData.refresh);
                resolve(res);
              });
            } else {
              this.logout();
              const err = new Error(E_ACCESS_TOKEN_REFRESH);
              err.response = res;
              reject(err);
            }
          })
          .catch((err) => {
            this.logout();
            reject(err);
          });
      } else {
        reject(new Error(E_REFRESH_TOKEN_UNAVAILABLE));
      }
    });
  }


  static sendPasswordReset(email) {
    return new Promise((resolve, reject) => {
      ServiceAgent.post({
        endpoint: apiUrls.user.resetPassword,
        params: { email },
        authenticate: false,
      }).then(resolve).catch(reject);
    });
  }


  static sendEmailOTP(email) {
    return new Promise((resolve, reject) => {
      ServiceAgent.post({
        endpoint: apiUrls.user.emailOTP,
        params: { email },
        authenticate: false,
      }).then(resolve).catch(reject);
    });
  }


  static sendSMSOTP(phone) {
    return new Promise((resolve, reject) => {
      ServiceAgent.post({
        endpoint: apiUrls.user.smsOTP,
        params: { phone },
        authenticate: false,
      }).then(resolve).catch(reject);
    });
  }


  static logout() {
    this.setAccessToken(null);
    this.setRefreshToken(null);

    // TODO: Clear token cookies?
    // const cookieOptions = {
    //   path: '/',
    //   domain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN,
    // };
  }
}

export default AuthManager;
