import React from 'react';

export default React.createContext({
  breakpoint: null,
  current_user: null,
  loadProgress: null,
  pageTitle: null,
  permissions: null,
  ticket_priority_choices: null,
  time_entry_types: null,
});
