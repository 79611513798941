import isEqual from 'lodash.isequal';

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';


import EditDialog from '@material-appkit/core/components/EditDialog';

import ServiceAgent from '@material-appkit/core/util/ServiceAgent';
import { useInit } from '@material-appkit/core/util/hooks';
import { titleCase } from '@material-appkit/core/util/string';

import Select from 'pat/components/Select';


const styles = makeStyles((theme) => ({
  select: {
    width: '100%',
  },
}));

function ModelSelectWidget(props) {
  const { apiCreateUrl, fieldInfo, titleKey, valueKey } = props;
  const { related_endpoint } = fieldInfo;

  const [options, setOptions] = useState(undefined);
  const [defaultCreateValues, setDefaultCreateValues] = useState(null);

  const entityType = titleCase(related_endpoint.singular);
  const apiListUrl = `${related_endpoint.singular}/`;

  useInit(async() => {
    try {
      const res = await ServiceAgent.get(apiListUrl, {
        disable_paging: true,
      });
      setOptions(res.jsonData);
    } catch (err) {
      setOptions([]);
    }
  });

  let selectedOption = null;
  if (options) {
    selectedOption = options.find((option) => {
      return isEqual(option, props.value);
    });
  }

  //----------------------------------------------------------------------------
  const handleSelectChange = (selection) => {
    props.onChange(selection);
  };

  const handleSelectCreate = (value) => {
    const defaultValues = {};

    if (typeof(titleKey) === 'string') {
      defaultValues[titleKey] = value;
    }

    setDefaultCreateValues(defaultValues);
  };

  const handleCreateDialogClose = () => {
    setDefaultCreateValues(null);
    // setCreateDialogOpen(false);
  };

  const handleCreateDialogSave = (record) => {
    setOptions([...options, record]);
    props.onChange(record);

    handleCreateDialogClose();
  };

  const labelForOption = (option) => {
    if (option.__isNew__) {
      return option.label;
    }

    if (typeof(titleKey) === 'string') {
      return option[titleKey];
    }

    return titleKey(option);
  };

  const classes = styles();

  return (
    <Fragment>
      <Select
        creatable={!!apiCreateUrl}
        className={classes.select}
        isClearable
        isLoading={options === undefined}
        getOptionLabel={labelForOption}
        getOptionValue={(option) => option[valueKey]}
        label={props.label}
        onChange={handleSelectChange}
        onCreate={handleSelectCreate}
        options={options}
        placeholder="---"
        value={selectedOption}
      />

      {(apiCreateUrl && defaultCreateValues) &&
        <EditDialog
          apiCreateUrl={apiCreateUrl}
          defaultValues={defaultCreateValues}
          entityType={entityType}
          onClose={handleCreateDialogClose}
          onSave={handleCreateDialogSave}
        />
      }
    </Fragment>
  );

}

ModelSelectWidget.propTypes = {
  apiCreateUrl: PropTypes.string,
  fieldInfo: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titleKey: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  valueKey: PropTypes.string,
  value: PropTypes.any,
};


ModelSelectWidget.toRepresentation = (value) => {
  return value ? value.url : null;
};

export default ModelSelectWidget;
