import dayjs from 'dayjs';

import AlertManager from '@material-appkit/core/managers/AlertManager';
import { lastPathComponent } from '@material-appkit/core/util/path';
import { matchPath } from '@material-appkit/core/util/urls';
import { valueForKeyPath } from '@material-appkit/core/util/object';

import ContentType from '@noundb/web-admin-core/util/ContentType';

import {
  DEFAULT_CURRENCY_LOCALIZATION_INFO,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATETIME_FORMAT,
} from 'pat/constants';


//------------------------------------------------------------------------------
// I/O
//------------------------------------------------------------------------------
export function abortRequest(requestContext) {
  if (requestContext && requestContext.abortController) {
    requestContext.abortController.abort();
  }
}

//------------------------------------------------------------------------------
// Error Handling
//------------------------------------------------------------------------------
export function failAlert(alertConfig) {
  AlertManager.error({
    ...alertConfig,
    onDismiss: () => {
      window.location.reload();
    },
  });
}


//------------------------------------------------------------------------------
// Rendering
//------------------------------------------------------------------------------
export function staticUrl(relativeUrl) {
  return `${process.env.PUBLIC_URL}/${relativeUrl}`;
}

const ENTITY_LABEL_MAP = () => ({
  [ContentType.typeIdForModel('invoice')]: 'title',
  [ContentType.typeIdForModel('farm')]: 'name',
  [ContentType.typeIdForModel('group')]: 'name',
  [ContentType.typeIdForModel('email')]: 'address',
  [ContentType.typeIdForModel('event')]: 'name',
  [ContentType.typeIdForModel('eventactivity')]: 'name',
  [ContentType.typeIdForModel('organization')]: 'name',
  [ContentType.typeIdForModel('phone')]: 'number',
  [ContentType.typeIdForModel('resource')]: 'name',
  [ContentType.typeIdForModel('userprofile')]: 'user.full_name',
});

export function itemLabel(item) {
  const entityLabelLookup = ENTITY_LABEL_MAP()[item.content_type];
  switch (typeof(entityLabelLookup)) {
    case 'string':
      return valueForKeyPath(item, entityLabelLookup);
    default:
      const { content_type, id } = item;
      const entityType = ContentType.labelForModel(content_type);
      return `${entityType} #${id}`;
  }
}

export function fullName(item) {
  if (!item) {
    return null;
  }

  if (typeof(item) === 'string') {
    return item;
  }

  for (const key of ['name', 'label', 'subject']) {
    if (item[key]) {
      return item[key];
    }
  }

  const user = item.user ? item.user : item;
  if (user.first_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return null;
}

export function ticketSubject(ticket) {
  return `${ticket.id}: ${ticket.subject}`;
}

export function eventActivityDateRange(activity) {
  return dateRangeFormat(
    activity.start_date,
    activity.end_date,
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
  );
}


export function mediaFilename(item) {
  return lastPathComponent(item.media_url);
}


export function getInitials(user) {
  let initials = '';
  if (user.first_name && user.first_name.length) {
    initials += user.first_name[0];
  }
  if (user.last_name && user.last_name.length) {
    initials += user.last_name[0];
  }
  return initials;
}


export function groupedItemCount(items) {
  let count = 0;
  items.forEach((itemGroup) => {
    count += itemGroup[1].length;
  });
  return count;
}


export function yesno(value) {
  return value ? 'Yes' : 'No';
}


export function attendanceCount(registrations) {
  return registrations.reduce(
    (acc, registration) => acc + (registration.attended ? 1 : 0)
    , 0);
}


export function websiteLink(website) {
  return website.name || website.address;
}

//------------------------------------------------------------------------------
// Transformers
//------------------------------------------------------------------------------
export function dateFormat(date, format) {
  return dayjs(date).format(format || DEFAULT_DATE_FORMAT);
}


export function dateTimeFormat(datetime, format) {
  return dayjs(datetime).format(format || DEFAULT_DATETIME_FORMAT);
}


export function dateRangeFormat(lb, ub, df, tf) {
  const startDate = dayjs(lb);
  const endDate = dayjs(ub);
  let result = startDate.format(df || DEFAULT_DATE_FORMAT);
  if (tf) {
    result = `${result} ${startDate.format(tf)}`;
  }

  if (!startDate.isSame(endDate, 'date')) {
    result = `${result} – ${endDate.format(df || DEFAULT_DATE_FORMAT)}`;
  }

  if (tf) {
    result = `${result} to ${endDate.format(tf)}`;
  }

  return result;
}


export function shortDate(dateString) {
  const date = dayjs(dateString);
  if (date.year() === dayjs().year()) {
    return date.format('MMM D');
  }
  return date.format('DD/MM/YY');
}


export const transformDateParam = (params, dateParamName) => {
  const beforeParam = `${dateParamName}_before`;
  const afterParam = `${dateParamName}_after`;

  if (params[dateParamName]) {
    const dateParts = params[dateParamName].split('|');
    params[afterParam] = dateParts[0];
    params[beforeParam] = dateParts[1];
  } else {
    delete params[beforeParam];
    delete params[afterParam];
  }
};


export function floatValue(value) {
  const floatValue = parseFloat(value);
  if (Number.isNaN(floatValue)) {
    return '';
  }
  return floatValue;
}


export function priorityInfo(value) {
  switch (value) {
    case 1:
      return { value: 1, label: 'Someday', color: '#000000' };
    case 2:
      return { value: 2, label: 'Low', color: '#a4a4a4' };
    case 3:
      return { value: 3, label: 'Standard', color: '#24aa16' };
    case 4:
      return { value: 4, label: 'High', color: '#e89014' };
    case 5:
      return { value: 5, label: 'Urgent', color: '#d03324' };
    default:
      return { value: 0, label: 'Invalid', color: '#000000' };
  }
}


export function urlToPrimaryKey(url, urlName) {
  const apiUrlPrefix = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_ENDPOINT_PREFIX;
  const relativeItemUrl = url.substring(apiUrlPrefix.length);
  const match = matchPath(relativeItemUrl, urlName);
  if (match && match.isExact) {
    return match.params.pk;
  }
  return null;
}


export const toCurrency = (value, precision, options) => {
  const formatOptions = options || {};

  const numericValue = parseFloat(value);
  if ((numericValue === 0 && !formatOptions.displayZero) || Number.isNaN(numericValue)) {
    return '---';
  }

  const localizationInfo = Object.assign(
    {},
    DEFAULT_CURRENCY_LOCALIZATION_INFO,
    formatOptions.localeOptions || {}
  );

  if (precision) {
    localizationInfo.maximumFractionDigits = precision;
  }

  return numericValue.toLocaleString('en', localizationInfo);
};


export function formatFileSize(bytes,decimalPoint) {
  if(bytes === 0) {
    return '0 Bytes';
  }

  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${value} ${sizes[i]}`;
}


export function sortUsersAlphabetically(users) {
  const sortedUsers = [...users];
  sortedUsers.sort(function(a, b) {
    const nameA = fullName(a).toLowerCase();
    const nameB = fullName(b).toLowerCase();
    return (nameA > nameB) ? 1 : -1;
  });
  return sortedUsers;
}

//------------------------------------------------------------------------------
// Math Utilities
//------------------------------------------------------------------------------
export const accumulate = (array, key) => {
  return array.reduce((value, item) => {
    value += parseFloat(item[key]);
    return value;
  }, 0);
};

//------------------------------------------------------------------------------
// Form Utilities
//------------------------------------------------------------------------------
export const formChangeInfo = (formChangeEvent) => {
  const formElement = formChangeEvent.target;
  const fieldName = formElement.name;
  if (!fieldName) {
    return null;
  }

  let fieldValue = null;

  switch (formElement.type) {
    case 'checkbox':
      fieldValue = formElement.checked;
      break;
    default:
      fieldValue = formElement.value;
  }

  return { [fieldName]: fieldValue };
};
