import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { SnackbarProvider } from 'notistack';

import AlertManager from '@material-appkit/core/managers/AlertManager';
import DialogManager from '@material-appkit/core/managers/DialogManager';
import SnackbarManager from '@material-appkit/core/managers/SnackbarManager';
import { useAsyncError, useWidth } from '@material-appkit/core/util/hooks';

import AuthManager from '@noundb/web-admin-core/managers/AuthManager';
import {
  E_ACCESS_TOKEN_REFRESH,
  E_REFRESH_TOKEN_UNAVAILABLE,
} from '@noundb/web-admin-core/constants/errors';

import PlaceholderLayout from 'pat/layout/PlaceholderLayout';

import AppContext from 'AppContext';
import AppDelegate from './AppDelegate';
import paths from 'paths';


const router = createBrowserRouter([
  {
    path: 'auth/*',
    lazy: () => import('pat/auth/AuthenticationLayout')
  },
  {
    path: '*',
    lazy: () => import('pat/layout/MainLayout')
  },
], {
  basename: process.env.REACT_APP_URL_BASENAME,
});


function App() {
  const breakpoint = useWidth();
  const throwError = useAsyncError();

  const [initialized, setInitialized] = useState(undefined);

  const [appContext, setAppContext] = useState({});
  const [pageTitle, setPageTitle] = useState('');
  const [loadProgress, setLoadProgress] = useState(null);

  const { site_profile } = appContext;

  const appDelegateRef = useRef();

  useEffect(() => {
    const appDelegate = new AppDelegate();
    appDelegateRef.current = appDelegate;

    const init = async() => {
      try {
        const res = await appDelegate.setUp();
        const sessionInfo = res[0];
        setAppContext(sessionInfo);
        setInitialized(true);
      } catch (err) {
        if (err.message === E_REFRESH_TOKEN_UNAVAILABLE || err.message === E_ACCESS_TOKEN_REFRESH) {
          AuthManager.logout();
          window.location.href = paths.auth.login;
        } else {
          const status = err.response?.status;
          switch (status) {
            case 401:
              window.location.href = paths.auth.login;
              break;
            default:
              throwError(err);
          }
        }
      }
    };

    init();

    return () => {
      appDelegate.tearDown();
    }
  }, [throwError]);


  const title = useMemo(() => {
    let pageTitleComponents = [];

    if (pageTitle) {
      if (Array.isArray(pageTitle)) {
        pageTitleComponents = [...pageTitle];
      } else {
        pageTitleComponents = [pageTitle];
      }
    }

    if (site_profile) {
      pageTitleComponents.push(site_profile.site.name);
    }

    return (
      <title>
        {pageTitleComponents.join(' | ')}
      </title>
    );
  }, [pageTitle, site_profile]);


  if (initialized === undefined) {
    return <PlaceholderLayout />
  }

  return (
    <AppContext.Provider value={{
      ...appContext,
      loadProgress, setLoadProgress,
      pageTitle, setPageTitle,
      breakpoint,
    }}>
      <HelmetProvider>
        <Helmet>
          {title}
        </Helmet>
      </HelmetProvider>

      <RouterProvider router={router} />

      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
      >
        <SnackbarManager />
      </SnackbarProvider>

      <AlertManager />
      <DialogManager />
    </AppContext.Provider>
  );
}

export default App;
