/**
*
* ProfileAssociationField
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import ItemList from '@material-appkit/core/components/ItemList';
import ListViewItem from '@material-appkit/core/components/ListViewItem';
import { removeObject } from '@material-appkit/core/util/array';

import { fullName } from 'pat/util/shortcuts';


function ProfileAssociationComponent(props) {
  const { item } = props;

  const handleRoleChange = (e) => {
    item.role = e.target.value;
    props.onChange(item);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="minmax(auto, 250px) 110px"
      gridColumnGap={8}
    >
      <ListItemText
        disableTypography
        primary={(
          <Link component={RouterLink} to={item.path} target="_blank">
            {fullName(item)}
          </Link>
        )}
      />

      <TextField
        onChange={handleRoleChange}
        select
        SelectProps={{ native: true }}
        value={item.role}
      >
        <option value="" />
        <option value="Advisor">Advisor</option>
        <option value="Producer">Producer</option>
        <option value="Expert">Expert</option>
        <option value="Organizer">Organizer</option>
        <option value="Interested">Interested</option>
        <option value="Former">Former</option>
      </TextField>
    </Box>
  );
}

ProfileAssociationComponent.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
};


class ProfileAssociationField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleItemListAdd = this.handleItemListAdd.bind(this);
    this.handleItemListRemove = this.handleItemListRemove.bind(this);
    this.handleItemListUpdate = this.handleItemListUpdate.bind(this);
    this.dispatchChangeEvent = this.dispatchChangeEvent.bind(this);
  }

  state = {
    listDialogOpen: false,
  };

  handleItemListAdd(items) {
    const newItems = this.props.value.slice();
    items.forEach((item) => {
      item.role = item.default_role || '';
      newItems.push(item);
    });
    this.dispatchChangeEvent(newItems);
  }

  handleItemListRemove(item) {
    const newItems = removeObject(this.props.value, 'id', item.id);
    this.dispatchChangeEvent(newItems);
  }

  handleItemListUpdate(item, itemIndex) {
    const newItems = this.props.value.slice();
    newItems[itemIndex] = item;
    this.dispatchChangeEvent(newItems);
  }

  dispatchChangeEvent(items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  render() {
    const {
      classes,
      fieldInfo,
      label,
      value,
    } = this.props;

    const listUrl = `${fieldInfo.related_endpoint.singular}/`;

    return (
      <FormControl fullWidth margin="none">
        <fieldset className={classes.fieldset}>
          {label &&
            <legend className={classes.legend}>{label}</legend>
          }

          <ItemList
            apiCreateUrl={this.props.createUrl}
            apiListUrl={listUrl}
            editDialogProps={this.props.editDialogProps}
            entityType={this.props.entityType}
            filterParams={this.props.filterParams}
            itemComponent={ProfileAssociationComponent}
            items={value}
            itemKeyPath={this.props.itemKeyPath}
            listDialogProps={{ selectionMode: 'multiple' }}
            listItemComponent={this.props.listItemComponent}
            listItemProps={this.props.listItemProps}
            mode="edit"
            onAdd={this.handleItemListAdd}
            onRemove={this.handleItemListRemove}
            onUpdate={this.handleItemListUpdate}
            searchFilterParam={this.props.searchFilterParam}
            titleKey={this.props.titleKey}
          />
        </fieldset>
      </FormControl>
    );
  }
}

ProfileAssociationField.propTypes = {
  classes: PropTypes.object.isRequired,
  createUrl: PropTypes.string,
  editDialogProps: PropTypes.object,
  entityType: PropTypes.string.isRequired,
  fieldInfo: PropTypes.object,
  filterParams: PropTypes.object,
  item: PropTypes.object,
  itemKeyPath: PropTypes.string,
  listItemComponent: PropTypes.any,
  listItemProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  searchFilterParam: PropTypes.string,
  titleKey: PropTypes.any.isRequired,
  value: PropTypes.array.isRequired,
};

ProfileAssociationField.defaultProps = {
  editDialogProps: {},
  filterParams: {},
  listItemComponent: ListViewItem,
  listItemProps: {},
};

export default withStyles((theme) => ({
  fieldset: theme.form.customControl.fieldset,
  legend: theme.form.customControl.legend,
}))(ProfileAssociationField);
