import dayjs from 'dayjs';

export const E_REQUEST_ABORTED = 'AbortError';

export const TAX_RATE = 0.05;

export const DEFAULT_CURRENCY_LOCALIZATION_INFO = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const DEFAULT_CHUNK_SIZE = 1024 * 1024; // 1024 KB

export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_TIME_FORMAT = 'h:mma';
export const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`;
export const TIMESTAMP_FORMAT = 'MMM Do, YYYY [at] h:mma z';

export const NEW_USER_DEFAULTS = {
  is_active: true,
};

export const NEW_TIME_ENTRY_DEFAULTS = {
  comment: '',
  date: () => dayjs(),
  rate: '',
  type: '',
  quantity: '',
};

export const NEW_NOTE_DEFAULTS = {
  subject: '',
  text: '',
  date_when: () => dayjs(),
};
