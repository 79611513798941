import * as Sentry from "@sentry/react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18n_en_US from "locales/en-US.json";

import Form from '@material-appkit/core/components/Form';
import NotificationManager from '@material-appkit/core/managers/NotificationManager';
import ServiceAgent from '@material-appkit/core/util/ServiceAgent';

import ContentType from '@noundb/web-admin-core/util/ContentType';
import ServiceProxy from '@noundb/web-admin-core/io/ServiceProxy';

import DateTime from 'pat/components/widgets/DateTime';
import ModelSelect from 'pat/components/widgets/ModelSelect';
import ProfileAssociationField from "pat/components/widgets/ProfileAssociationField";

import apiUrls from "api-urls";


class AppDelegate {
  constructor() {
    this._initAnalytics();
    this._initForms();

    ServiceAgent.initialize({
      ServiceProxyClass: ServiceProxy,
    });

    NotificationManager.addObserver(this, this.authenticationDidSucceed, 'authenticate', null);
  }

  authenticationDidSucceed(notification) {
    const { redirect } = notification.context;

    // Upon successful authentication, trigger a complete app reload
    window.location.href = redirect || process.env.REACT_APP_URL_BASENAME;
  }

  _initAnalytics() {
    // Enable error logging via Sentry.io
    if (parseInt(process.env.REACT_APP_SENTRY_LOGGING) === 1) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_ID,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      });
    }
  };

  _initForms() {
    Form.registerWidgetClass('date', DateTime);
    Form.registerWidgetClass('datetime', DateTime);
    Form.registerWidgetClass('date_and_time', DateTime);
    Form.registerWidgetClass('modelselect', ModelSelect);
    Form.registerWidgetClass('profileassociationfield', ProfileAssociationField);
  }

  async _initLocale() {
    const t = await i18n.use(initReactI18next).init({
      resources: {
        en: i18n_en_US,
      },
      lng: "en", // if you're using a language detector, do not define the lng option
      fallbackLng: "en",

      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });

    return t;
  }

  async _initSession() {
    try {
      const res = await ServiceAgent.get(apiUrls.initialize);
      const sessionInfo = res.jsonData;
      const { content_types } = sessionInfo;
      if (content_types) {
        Object.keys(content_types).forEach((contentTypeId) => {
          const contentType = content_types[contentTypeId];
          contentType.id = contentTypeId;
          const i18nKey = `CONTENT_TYPE_${contentType.model}`;
          contentType.label = i18n.t(i18nKey, { count: 1 });
          contentType.pluralLabel = i18n.t(i18nKey, { count: 0 });
        });
        ContentType.initializeContentTypes(content_types);
      }
      return sessionInfo;
    } catch (err) {
      throw(err);
    }
  }

  setUp = async() => {
    const responses = await Promise.all([
      this._initSession(),
      this._initLocale(),
    ]);

    return responses;
  }

  tearDown = async () => {
    console.log('Tearing down application');
  }
}

export default AppDelegate;
