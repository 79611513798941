import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from "react-i18next";

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

// import DebugManager from '@material-appkit/core/managers/DebugManager';

const styles = makeStyles((theme) => ({
  main: {
    background: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
  },

  contentContainer: {
    flex: 1,
  },

  content: {
    margin: `${theme.spacing(3)}px auto`,
    padding: theme.spacing(2),
    maxWidth: 500,
    width: '80%',
    textAlign: 'center',
  },

  warningIcon: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(64),
  },
}));


function CriticalErrorView({ error, componentStack, resetError }) {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <main className={classes.main}>
      <Paper className={classes.content}>
        <WarningRoundedIcon className={classes.warningIcon} />

        <Typography variant="h3" gutterBottom>
          {t('E_GENERIC_TITLE')}
        </Typography>
        <Typography variant="subtitle1">
          {t('E_GENERIC_SUBTITLE')}
        </Typography>

        <IconButton onClick={() => window.location.reload()}>
          <ReplayRoundedIcon fontSize="large" />
        </IconButton>
      </Paper>
    </main>
  );
}

CriticalErrorView.propTypes = {
  error: PropTypes.object,
  componentStack: PropTypes.string,
  resetError: PropTypes.func,
};

export default CriticalErrorView;
