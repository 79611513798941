class ContentType {
  static _contentTypeInfoMap = null;
  static _modelContentTypeMap = null;

  static initializeContentTypes(contentTypeInfoMap) {
    this._contentTypeInfoMap = contentTypeInfoMap;

    const modelContentTypeMap = new Map();
    Object.keys(contentTypeInfoMap).forEach((contentTypeId) => {
      const contentTypeInfo = contentTypeInfoMap[contentTypeId];
      modelContentTypeMap.set(contentTypeInfo.model, parseInt(contentTypeId));
    });
    this._modelContentTypeMap = modelContentTypeMap;
  }

  static contentTypeWithId(contentTypeId) {
    return this._contentTypeInfoMap[contentTypeId];
  }

  static typeIdForModel(modelName) {
    if (!this._modelContentTypeMap) {
      throw new Error('Do not attempt to access content types before they are loaded');
    }

    const contentTypeId = this._modelContentTypeMap.get(modelName);
    if (!contentTypeId) {
      throw new Error(`Unknown content type: ${modelName}`);
    }

    return contentTypeId;
  }

  static getForModel(modelName) {
    let contentTypeId = modelName;
    if (typeof(contentTypeId) === 'string') {
      contentTypeId = this.typeIdForModel(contentTypeId);
    }
    return this._contentTypeInfoMap[contentTypeId];
  }

  static labelForModel(modelName, plural) {
    let contentTypeId = modelName;
    if (typeof(contentTypeId) === 'string') {
      contentTypeId = this.typeIdForModel(contentTypeId);
    }

    const contentType = this._contentTypeInfoMap[contentTypeId];
    if (contentType) {
      return plural ? contentType.pluralLabel : contentType.label;
    }

    return null;
  }
}

export default ContentType;
