import * as Sentry from '@sentry/react';

import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import dayjsUTCPlugin from 'dayjs/plugin/utc';
import dayjsTimezonePlugin from 'dayjs/plugin/timezone';
import dayjsAdvancedFormatPlugin from 'dayjs/plugin/advancedFormat';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import { createRoot } from 'react-dom/client';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';

// Import any static resources here

import theme from 'theme';
import App from 'pat/App.js';
import CriticalErrorView from 'pat/CriticalErrorView';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      // staleTime: 0,
    },
  },
});


function CriticalErrorFallback(props) {
  const { error, componentStack, resetError } = props;

  return (
    <CriticalErrorView
      error={error}
      componentStack={componentStack}
      resetError={resetError}
    />
  );
}


function initialize() {
  if (parseInt(process.env.REACT_APP_SENTRY_LOGGING) === 1) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
  }

  dayjs.extend(dayjsUTCPlugin);
  dayjs.extend(dayjsTimezonePlugin);
  dayjs.extend(dayjsAdvancedFormatPlugin);
  dayjs.tz.setDefault('America/Los_Angeles');

  const root = createRoot(document.getElementById('app'));
  root.render(
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <Sentry.ErrorBoundary fallback={CriticalErrorFallback}>
            <CssBaseline />
            <App />
          </Sentry.ErrorBoundary>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
}

initialize();

/*
function initialize() {
  return new Promise((resolve, reject) => {
    const preloaderLoadDidComplete = (responses) => {
      const theme = createTheme(themeInfo);

      const application = (
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              autoHideDuration={3000}
            >
              <SnackbarManager />
            </SnackbarProvider>

            <AlertManager />
            <App
              initInfo={responses[0]}
              onLayoutDidMount={resolve}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      );

      ReactDOM.render(application, document.getElementById('root'));
    };

    ReactDOM.render((
      <AppPreloader
        onLoadComplete={preloaderLoadDidComplete}
        onLoadError={reject}
      />
    ), document.getElementById('preloader'));
  });
}

initialize().then(() => {
  const preloader = document.getElementById('preloader');
  window.gsap.to(preloader, { opacity: 0 }).then(() => {
    document.body.removeChild(preloader);
  });
}).catch((err) => {
  logException(err);

  if (window.confirm("Unable to initialize application.\n\nVerify that you're online, then press OK to try again.")) {
    NavManager.reloadWindow();
  }
});
*/