/**
*
* Select
* See: https://material-ui.com/demos/autocomplete/#react-select
*/

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CancelIcon from '@material-ui/icons/Cancel';

// -----------------------------------------------------------------------------
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
NoOptionsMessage.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
inputComponent.propTypes = {
  inputRef: PropTypes.func,
};

// -----------------------------------------------------------------------------
function Control(props) {
  const sizeClassName = `input_size_${props.selectProps.textFieldProps.size}`;
  const inputClasses = [
    props.selectProps.classes.input,
    props.selectProps.classes[sizeClassName],
  ];

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: clsx(inputClasses),
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{ fontWeight: props.isSelected ? 500 : 400 }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

// -----------------------------------------------------------------------------
function Placeholder(props) {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};


// -----------------------------------------------------------------------------
function SingleValue(props) {
  return (
    <Typography {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.any,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

// -----------------------------------------------------------------------------
const components = {
  Control,
  Menu,
  // MultiValue,
  NoOptionsMessage,
  // Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


const styles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    height: 'unset',
  },

  input_size_medium: {
    padding: '6px 0',
  },

  input_size_small: {
    padding: 0,
  },

  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: '8px 12px',
  },

  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },

  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },

  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

function Select(props) {
  const {
    creatable,
    label,
    size,
    ...selectProps
  } = props;

  let SelectComponent = ReactSelect;
  if (creatable) {
    SelectComponent = CreatableSelect;
    selectProps.onCreateOption = props.onCreate;
  }

  const classes = styles();

  return (
    <SelectComponent
      classes={classes}
      components={components}
      textFieldProps={{
        margin: 'none',
        label,
        InputLabelProps: {
          shrink: true,
        },
        size,
        variant: 'outlined',
      }}
      {...selectProps}
    />
  );
}

Select.propTypes = {
  creatable: PropTypes.bool,
  label: PropTypes.string,
  onCreate: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),

};

Select.defaultProps = {
  creatable: false,
  size: 'medium',
};

export default Select;
