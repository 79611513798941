import dayjs from 'dayjs';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import AlarmIcon from '@material-ui/icons/Alarm';

import {
  KeyboardDatePicker,
  DateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';


const styles = makeStyles((theme) => ({
  ...theme.form.customControl,

  fieldContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gridColumnGap: theme.spacing(1),
  },
}));

function DateTimeWidget(props) {
  let initialDate = null;

  if (props.value) {
    initialDate = props.value;

    if (typeof initialDate === 'string') {
      initialDate = dayjs(initialDate);
    }
  }

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleDateChange = (value) => {
    if (!(value && value.isValid())) {
      return;
    }

    setSelectedDate(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleTimeChange = (value) => {
    if (!(value && value.isValid())) {
      return;
    }

    value.year(selectedDate.year());
    value.month(selectedDate.month());
    value.date(selectedDate.date());
    setSelectedDate(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };


  const classes = styles();

  const renderField = () => {
    const { type, ui } = props.fieldInfo;
    let widgetType = (ui && ui.widget) || type;

    let dateLabel = props.label;
    let DateComponent = KeyboardDatePicker;
    let dateComponentFormat = "YYYY/MM/DD";

    switch (widgetType) {
      case 'date':
        break;
      case 'datetime':
        DateComponent = DateTimePicker;
        dateComponentFormat = null;
        break;
      case 'date_and_time':
        dateLabel = `${dateLabel} Date`;
        break;
      default:
        throw new Error(`Unsupported type: ${type}`);
    }

    const datePicker = (
      <DateComponent
        autoOk
        format={dateComponentFormat}
        label={dateLabel}
        onChange={handleDateChange}
        value={selectedDate}
      />
    );

    if (widgetType !== 'date_and_time') {
      return datePicker;
    }

    return (
      <>
        {datePicker}

        <KeyboardTimePicker
          disabled={!selectedDate}
          keyboardIcon={<AlarmIcon />}
          label={`${props.label} Time`}
          onChange={handleTimeChange}
          minutesStep={5}
          value={selectedDate}
        />
      </>
    );
  };

  return (
    <FormControl fullWidth margin="none">
      <fieldset className={classes.fieldset}>
        <div className={classes.fieldContainer}>
          {renderField()}
        </div>
      </fieldset>
    </FormControl>
  );
}

DateTimeWidget.toRepresentation = (value, fieldInfo) => {
  if (typeof(value) === 'string') {
    return value;
  }

  if (fieldInfo.type === 'date') {
    return value.format('YYYY-MM-DD');
  }

  return value.format();
};

DateTimeWidget.propTypes = {
  fieldInfo: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DateTimeWidget;
