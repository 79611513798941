import { include } from '@material-appkit/core/util/path';

const paths = {
  index: '/',
  dashboard: '/dashboard',

  auth: include('/auth', {
    index: '*',
    login: 'sign-in',
    basic: 'basic',
    emailOTP: 'email-otp',
    smsOTP: 'sms-otp',
    otp: 'otp/:uidb64/:token',
    forgotPassword: 'forgot-password',
    passwordResetMailSent: 'password-reset-email-sent',
    setPassword: 'set-password/:uidb64/:token',
    setPasswordDone: 'set-password/done',
    activateAccount: 'activate/:uidb64/:token',
  }),

  admin: include('/admin', {
    search: 'search',

    email: include('/email', {
      import: 'import/',
      importResult: 'import/result/',
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    farm: include('/farms', {
      list: '',
      retrieve: ':pk/*',
      update: ':pk/edit',
    }),

    group: include('/groups', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    event: include('/events', {
      list: '',
      retrieve: ':pk/*',
      update: ':pk/edit',
    }),

    eventactivity: include('/events/:event_id/activities', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    invoice: include('/invoices', {
      list: '',
      retrieve: ':pk',
    }),

    note: include('/notes', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    organization: include('/organizations', {
      list: '',
      retrieve: ':pk/*',
      update: ':pk/edit',
    }),

    phone: include('/phone', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    profile: include('/profile', {
      index: '',
      retrieve: ':pk',
      preferences: '/preferences',
    }),

    resource: include('/resources', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    ticket: include('/tickets', {
      list: '',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    timeEntry: include('/time-entries', {
      list: '',
      create: 'create',
      retrieve: ':pk',
      update: ':pk/edit',
    }),

    user: include('/people', {
      list: '',
      retrieve: ':pk/*',
      update: ':pk/edit',
    }),
  }),
};

export default paths;
