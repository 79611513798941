import { include } from '@material-appkit/core/util/path';

const urls = {
  initialize: 'initialize/',
  search: 'search/',

  activityreport: include('activityreport/', {
    list: '',
    generate: 'generate/',
    distinctCreationDates: 'distinct_creation_dates/',
  }),

  auth: include('auth', {
    jwt: include('jwt', {
      create: 'create/',
      refresh: 'refresh/',
      verify: 'verify/',
    }),

    passwordReset: 'password-reset/',
    passwordResetConfirm: 'password-reset/:uidb64/:token/',
  }),

  comment: include('comment/', {
    details: ':pk/',
    list: '',
  }),

  email: include('email/', {
    create: '',
    details: ':pk/',
    list: '',
    importMailchimpData: 'import_mailchimp_data/',
    exportMailchimpData: 'export_mailchimp_data/',
  }),

  event: include('event/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  eventactivity: include('eventactivity/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  eventactivityregistration: include('eventactivityregistration/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  farm: include('farm/', {
    batch_update_tags: 'batch_update_tags/',
    create: '',
    details: ':pk/',
    list: '',
    merge: 'merge/',
  }),

  farmlocation: include('farmlocation/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  farmproduct: include('farmproduct/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  group: include('group/', {
    create: '',
    list: '',
    details: ':pk/',
  }),

  invoice: include('invoice/', {
    details: ':pk/',
    generate: 'generate/',
    list: '',
  }),

  note: include('note/', {
    batch_update_tags: 'batch_update_tags/',
    create: '',
    details: ':pk/',
    list: '',
    merge: 'merge/',
  }),

  organization: include('organization/', {
    batch_update_tags: 'batch_update_tags/',
    create: '',
    details: ':pk/',
    list: '',
    merge: 'merge/',
  }),

  phone: include('phone/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  profile: include('userprofile/', {
    batch_update_tags: 'batch_update_tags/',
    details: ':pk/',
    list: '',
    merge: 'merge/',
  }),

  resource: include('resource/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  tag: include('tag/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  ticket: include('ticket/', {
    batch_update_tags: 'batch_update_tags/',
    create: '',
    details: ':pk/',
    list: '',
  }),

  timeEntry: include('time_entry/', {
    create: '',
    details: ':pk/',
    invoice: 'invoice/',
    list: '',
  }),

  town: include('town/', {
    create: '',
    list: '',
  }),

  user: include('user/', {
    create: '',
    details: ':pk/',
    list: '',
  }),

  website: include('website/', {
    create: '',
    list: '',
  }),
};

export default urls;
