import APIServiceProxy from '@material-appkit/core/util/ajax/APIServiceProxy';

import AuthManager from '../managers/AuthManager';


class ServiceProxy extends APIServiceProxy {
  static credentials = 'include';

  request(method, ...args) {
    let endpoint, params, context, headers, cacheVersion, authenticate;

    if (typeof(args[0]) === 'object') {
      endpoint = args[0].endpoint;
      params = args[0].params;
      context = args[0].context;
      headers = args[0].headers;
      cacheVersion = args[0].cacheVersion;
      authenticate = args[0].authenticate ?? true;
    } else {
      [endpoint, params, context, headers, cacheVersion] = args;
      authenticate = true;
    }

    return new Promise((resolve, reject) => {
      const requestHeaders = this.constructor.getRequestHeaders(headers, params, authenticate);
      const firstRequestInfo = this.requestInfo(method, endpoint, params, requestHeaders, cacheVersion);

      if (context) {
        context.abortController = firstRequestInfo.abortController;
      }

      this._performRequest(firstRequestInfo).then((firstResponse) => {
        if ((firstResponse.status === 401 || firstResponse.status === 403) && authenticate) {
          AuthManager.refreshAccessToken().then(() => {
            const refreshedAccessToken = AuthManager.getAccessToken();
            requestHeaders.Authorization = `Bearer ${refreshedAccessToken}`;
            const secondRequestInfo = this.requestInfo(method, endpoint, params, requestHeaders, cacheVersion);

            this._performRequest(secondRequestInfo).then((secondResponse) => {
              this.handleResponse(secondResponse, resolve, reject);
            }).catch((err) => {
              reject(err);
            });
          }).catch((err) => {
            reject(err);
          });
        } else {
          this.handleResponse(firstResponse, resolve, reject);
        }
      }).catch((firstRequestError) => {
        if (firstRequestError.name !== 'AbortError') {
          reject(firstRequestError);
        }
      });
    });
  }
}

export default ServiceProxy;
